import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import Seo from '../components/Seo/Seo';
import Layout from '../Layout/Layout';

let classNames = require('classnames');

const options = {
  chart: {
    type: 'spline',
  },
  title: {
    text: 'My chart',
  },
  series: [
    {
      data: [1, 2, 1, 4, 3, 6],
    },
  ],
};

const options2 = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'Monthly Average Rainfall',
  },
  subtitle: {
    text: 'Source: WorldClimate.com',
  },
  xAxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Rainfall (mm)',
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: 'Tokyo',
      data: [
        49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
        95.6, 54.4,
      ],
    },
    {
      name: 'New York',
      data: [
        83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6,
        92.3,
      ],
    },
    {
      name: 'London',
      data: [
        48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2,
      ],
    },
    {
      name: 'Berlin',
      data: [
        42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1,
      ],
    },
  ],
};

const pieChart = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: 'Browser market shares in January, 2018',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
      },
    },
  },
  series: [
    {
      name: 'Brands',
      colorByPoint: true,
      data: [
        {
          name: 'Chrome',
          y: 61.41,
          sliced: true,
          selected: true,
        },
        {
          name: 'Internet Explorer',
          y: 11.84,
        },
        {
          name: 'Firefox',
          y: 10.85,
        },
        {
          name: 'Edge',
          y: 4.67,
        },
        {
          name: 'Safari',
          y: 4.18,
        },
        {
          name: 'Sogou Explorer',
          y: 1.64,
        },
        {
          name: 'Opera',
          y: 1.6,
        },
        {
          name: 'QQ',
          y: 1.2,
        },
        {
          name: 'Other',
          y: 2.61,
        },
      ],
    },
  ],
};

const dunotChart = {
  chart: {
    renderTo: 'container',
    type: 'pie',
  },
  title: {
    text: 'Browser market share, April, 2011',
  },
  yAxis: {
    title: {
      text: 'Total percent market share',
    },
  },
  plotOptions: {
    pie: {
      shadow: false,
    },
  },
  tooltip: {
    formatter: function () {
      return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
    },
  },
  series: [
    {
      name: 'Browsers',
      data: [
        ['Firefox', 6],
        ['MSIE', 4],
        ['Chrome', 7],
      ],
      size: '60%',
      innerSize: '80%',
      showInLegend: true,
      dataLabels: {
        enabled: true,
      },
    },
  ],
};

const colasChart = {
  chart: {
    type: 'line',
  },
  credits: {
    enabled: false
  },
  xAxis: {
    gridLineDashStyle: 'longdash',
    categories: ['2013', '2014', '2015', '2016', '2017', '2018'],
    tickmarkPlacement: 'on',
    title: {
      enabled: false,
    },
  },
  yAxis: {
    //gridLineDashStyle: 'longdash',
    labels: {
      format: '{value} MT',
    },
    title: {
      enabled: true,
      text: 'Million de tonnes',
    },
  },
  series: [
    {
      name: 'Milliers de tonnes',
      data: [180, 80, 105, 190, 160, 170],
      color: '#707070',
      valueSuffix: 'MT',
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 7.5,
        fillColor: '#ffed00',
        lineColor: '#707070',
        lineWidth: 1
      },
    },
  ],
  tooltip: {
    pointFormat: '<b>{point.percentage:.1f}</b> ({point.y:,.0f}) MT',
    split: false,
  },
};

const HighChart = ({ data, pageContext }) => {
  return (
    <Layout>
      <Seo
        title="Colas Home Page"
        description="Description de la Home Page de Colas"
      />
      <div className={classNames('page_template', 'page_test')}>
        <p>--</p>
        <p>--</p>

        <div className="wrapper_page_xs hightchart_container">
          <HighchartsReact highcharts={Highcharts} options={options} />
          <HighchartsReact highcharts={Highcharts} options={options2} />
          <HighchartsReact highcharts={Highcharts} options={pieChart} />
          <div className="col_2">
            <HighchartsReact highcharts={Highcharts} options={dunotChart} />
            <HighchartsReact highcharts={Highcharts} options={colasChart} />
          </div>
        </div>

        <p>--</p>
        <p>--</p>

        {/* <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              }
            ]
          }
        />

        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              }
            ]
          }
        />

        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        />

        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        />

        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        />

        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        />
        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        />
        <KeyNumbers
          textColor="white"
          NoPadding={true}
          locale="fr"
          datas={
            [
              {
                number: '12,3',
                title: 'Mds€ De Chiffre d’Affaires'
              },
              {
                number: '3 800',
                title: 'Implantations dans le monde'
              },
              {
                number: '54 600',
                title: 'COLLABORATEURS <span>(AU 31 DÉCEMBRE 2020)</span> EFFECTIF MOYEN SUR L’ANNÉE : 59 400'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              },
              {
                number: '3 000',
                title: 'UNITÉS DE PRODUCTION ET RECYCLAGE DE MATÉRIAUX'
              }
            ]
          }
        /> */}
      </div>
    </Layout>
  );
};

HighChart.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default HighChart;
